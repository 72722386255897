@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: 'Instrument Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Banner {
  background-color: #ECEBEB;
  width: 100%;
  min-height: 100vh;
}

.custom-table thead,
.custom-table tbody {
  background-color: transparent !important;
}
::-webkit-scrollbar {
  display: none;
}

.hover\:bg-light-gold:hover {
  background-color: #c2c2c2;
}

.hover-gray:hover {
  background-color: #dcdcdc;
  color: black;
}

.leading-tight {
  line-height: 1.8;
  background-color: transparent;
}
select option:checked {
  background-color: #fd719b !important;
  color: #fdfdfd !important;
}
.css-13cymwt-control {
  background-color: transparent !important;
}
input::placeholder{
    color: #7D7D7D !important;
}
input[type="text"]::placeholder {
  color: #7D7D7D; /* Change the placeholder text color here */
}
input[type="number"]::placeholder {
  color: #7D7D7D; /* Change the placeholder text color here */
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;  appearance: none;  height: 30px;
  width: 30px;
  background-image: url("../assets/images/times.svg");
  background-size:cover;
  background-position: right;
}
.activePage {
  background-color: #B20036 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
  background-color: rgb(255, 255, 255) !important;
  -webkit-text-fill-color: #494949 !important;
}

.your-container-class {
  position: relative;
}

.your-container-class .notranslate {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust the left spacing as needed */
  transform: translateY(-50%);
  color: #494949; /* Change the color as per your design */
  font-size: 15px !important;
}

/* ---------------------------------------------------------------------------------------------------------------------------------------*/

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 174 / var(--tw-bg-opacity));
}

.rounded-3xl{
    border-radius: 20px !important;
}

.bg-thead{
  background-color: #fff !important;
}

.font-medium{
  font-weight: 500 !important;
}

.dropshadow-4xl{
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20) !important;
}

.dropshadow-3xl{
  box-shadow: 0px 4px 30px 0px rgba(255, 255, 255, 0.08) !important;
}

.dropshadow-2xl{
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.10) !important;
}

.dropshadow-xl{
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05) !important;
}

.filter-md{
  filter: blur(4px);
}

.filter-xs{
  filter: blur(2px);
}

.filter-lg{
  filter: blur(8px);
}

.css-30720b-placeholder{
  color: #494949 !important;
}

.css-1550uzu-placeholder{
  color: #494949 !important;
}

.css-iembch-control{
  background-color: #fff !important;
}

.w-22{
  width: 75px;
}

.h-22{
  height: 75px;
}

input{
  outline: none !important;
}

.custom-select {
  background: #B20036 !important;
  color: #fff !important
}
/* .option:checked{
  color: #000 !important
}
  */
option:not(:checked) {
  background-color: #fff !important;
  color: #000 !important;
}
.rounded-xl{
  border-radius: 10px !important;
}

@media(max-width:576px){
  .css-q87423-control{
    width: 100% !important;
  }
}
