*:not(#scrollableDiv)::-webkit-scrollbar {
  display: unset;
}

body {
  overflow: hidden;
}

::-webkit-scrollbar {
    display: unset;
}
